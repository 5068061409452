.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.background {
  width: 100vw;
  height: 68.5rem;
  background-image: url('../assets/background.png');
  background-repeat: no-repeat;
}
