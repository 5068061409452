//----- Colors
$color-black: #000000;
$color-blue: #1a79d5;
$color-gray: #85979f;
$color-green: #01e6a3;
$color-orange: #f19400;
$color-red: #d64242;
$color-white: #ffffff;

//----- Fonts
$font-light: 300;
$font-regular: 400;
$font-bold: 700;

$font-size-s: 1.6rem;
$font-size-m: 1.8rem;
$font-size-l: 2.4rem;
$font-size-xl: 3rem;
$font-size-xll: 3.6rem;

$line-height-s: 2.4rem;
$line-height-m: 2.7rem;
$line-height-l: 3.6rem;
$line-height-xl: 4.5rem;
$line-height-xll: 5.4rem;

//----- Margins -----
$margin-xs: 1rem;
$margin-s: 2rem;
$margin-m: 3rem;
$margin-l: 5rem;
$margin-xl: 10rem;
$margin-xll: 15rem;

//----- Paddings -----
$padding-xs: 1rem;
$padding-s: 2rem;
$padding-m: 3rem;
$padding-l: 5rem;
$padding-xl: 10rem;
$padding-xll: 15rem;
