@import '../../theme/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background: linear-gradient(90deg, rgba(102, 118, 220, 0.49) 0%, rgba(51, 75, 115, 0.49) 100%);
}

.innerWrapper {
  width: 100%;
  padding: 4rem;
  color: $color-white;
}

.logo {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  margin-bottom: 5rem;
  border-bottom: 0.2rem solid $color-white;

  &__image {
    width: 35.1rem;
    height: 10.94rem;
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.time {
  font-size: 11.2rem;
  line-height: 13.4rem;
}

.paragraph {
  text-transform: capitalize;
  font-size: 2.5rem;
  line-height: 3rem;

  &__temperature {
    font-weight: $font-light;
    font-size: 11.2rem;
    line-height: 13.4rem;
  }

  &__time {
    font-size: 11.2rem;
    line-height: 13.4rem;
  }
}

.weather {
  display: flex;

  &__icon {
    width: 9rem;
    height: 9rem;
    margin-left: 4rem;
  }
}
