@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.alert {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 1.2rem 4rem;
  background: $color-blue;
  color: $color-white;
  font-weight: $font-bold;
  font-size: $font-size-m;
  line-height: $line-height-s;

  &Warning {
    @extend .alert;

    background: $color-red;
  }

  &__icon {
    width: 3.6rem;
    height: 3.2rem;
    margin-right: 2rem;
  }

  &__text {
    margin-left: 2rem;
    padding-left: 2rem;
    border-left: 0.2rem solid $color-white;
  }
}

.wrapper {
  position: relative;
}

.weather {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 47.1rem;
  padding: 3rem 4rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.89) 0%, #f5f5f5 47.92%, #e9e9e9 100%);

  &__actual {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30%;
  }

  &__icon {
    width: 10rem;
    height: 10rem;
    margin-right: 2rem;
  }

  &__iconSmall {
    width: 4.1rem;
    height: 4.1rem;
    margin-right: 1.5rem;
  }

  &__name {
    font-weight: $font-bold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    letter-spacing: 0.075rem;
    margin-bottom: 3rem;
  }

  &__temperature {
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 7.2rem;
  }

  &__text {
    font-weight: $font-regular;
    font-size: $font-size-l;
    line-height: $line-height-l;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    font-weight: $font-regular;
    font-size: $font-size-s;
    line-height: $line-height-s;
    margin-bottom: 4rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
