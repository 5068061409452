@import '../../theme/variables';

.wrapper {
  color: $color-white;
  font-weight: $font-bold;
  font-size: 3.4rem;
  line-height: 4.1rem;
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  background: rgba(49, 71, 112, 0.86);
}

.dot {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 3rem;
  background-color: $color-green;

  &Red {
    @extend .dot;

    background-color: $color-red;
  }
}

.icon {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem;
}

.paragraph {
  display: flex;
  align-items: center;
  margin-right: 3rem;
}
