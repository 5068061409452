@import '../../../theme/variables';

.wrapper {
  width: 100vw;
  padding: 2rem 4rem;
  font-weight: $font-regular;
  color: #313131;
  background: rgba(255, 255, 255, 0.89);
}

.weather {
  display: flex;
  justify-content: space-between;
  list-style: none;
  font-size: 2.3rem;
  line-height: 2.8rem;

  &__border {
    height: 10rem;
    border-right: 0.2rem solid $color-white;
  }

  &__bold {
    font-weight: $font-bold;
  }

  &__big {
    font-size: 5rem;
  }

  &__color {
    color: #126880;
  }

  &__icon {
    width: 4.2rem;
    height: 4.2rem;
    margin-right: 1.2rem;
  }

  &__item {
    display: flex;
    align-items: flex-end;
    padding-bottom: 2.6rem;

    &:last-of-type {
      border-right: none;
      padding-right: 0;
    }
  }
}
